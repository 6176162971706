import React, { useState, useEffect  } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import { useUserAuth } from "../../../context/UserAuthContext";
import { Navbar, Nav, Dropdown } from 'react-bootstrap'; // Import Bootstrap components
// Styles
import "./style.css";

// Helper function to get initials from a full name
const getInitials = (fullName) => {
  const names = fullName.split(' ');
  const initials = names.map(name => name.charAt(0).toUpperCase()).join('');
  return initials;
};
const Header = () => {
  const [email, setEmail] = useState("");
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="container mt-2">
      <Navbar  expand="md" className="">
        <Navbar.Brand as={Link} to="/home" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
          <span className="fs-4"><img className="logo" src="https://yieldlab.ai/asset/images/YieldLab-Logo.svg" alt="Yield Lab" /></span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav" className="justify-content-end">
          <Nav className="nav nav-pills">
            <Nav.Link as={Link} to="/home">Home</Nav.Link>
            <Dropdown className="payment-drp">
              <Dropdown.Toggle>
                <Nav.Link as={Link} to="/payment-trends">Payment trends</Nav.Link>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-end text-center">
              <Dropdown.Item><Nav.Link as={Link} to="/payment-trends/credit-cards">Credit Card Trends</Nav.Link></Dropdown.Item>
              <Dropdown.Item><Nav.Link as={Link} to="/payment-trends/debit-cards">Debit Card Trends</Nav.Link></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Link as="a" href="https://yieldlab.ai/blog" rel="noopener noreferrer">
              Blog
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/payment-trends">Payment trends</Nav.Link> */}
            <Nav.Link as={Link} to="/about-us">About Us</Nav.Link>
            {/* <Nav.Link as={Link} to="/privacy-policy">Privacy Policy</Nav.Link> */}
            {user ? (
              <Dropdown>
                <Dropdown.Toggle variant="btn btn-primary dropdown-toggle rounded-pill">
                  <span className="rounded-pill">{ getInitials(user.displayName) } </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end text-center">
                  <Dropdown.Item className="p-3"><span className="rounded-pill btn btn-primary">{ getInitials(user.displayName) }</span></Dropdown.Item>
                  <Dropdown.Item><span><h1>{user.displayName}</h1></span></Dropdown.Item>
                  <Dropdown.Item><span><b>{user.email}</b></span></Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              // <Nav.Link as={Link} to="/signin" className="btn btn-primary">Sign up</Nav.Link>
              <Link to="/signin" className="btn btn-primary">Sign in</Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Header;