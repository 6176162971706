import React, { useState, useEffect } from "react";
import { Button, Modal, Form, Row, Col, Dropdown } from "react-bootstrap";
import Chart from "react-apexcharts";
import { useUserAuth } from "../../../context/UserAuthContext";
import { useNavigate } from "react-router";

const AverageTicketSize = (props) => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const selectedCardType = props.selectedCardType;
  const [selectedPeriod, setSelectedPeriod] = useState('Month');
  const selectedEntity = props.selectedBankType ;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);

  // New state for modal
  const [showModal, setShowModal] = useState(false);
  const [selectedFirstCompare, setSelectedFirstCompare] = useState('');
  const [selectedSecondCompare, setSelectedSecondCompare] = useState('');
  const [selectedIndustyCompare, setSelectedIndustyCompare] = useState('');

  const topBanks = [
    "HDFC Bank",
    "ICICI Bank",
    "Axis Bank",
    "State Bank of India",
    "IndusInd Bank",
    "IDFC First Bank",
    "Kotak Mahindra Bank",
    "Federal Bank"
  ];
  
  const otherBanks = [
    "American Express",
    "Au Small Finance Bank",
    "Axis Bank",
    "Bandhan Bank",
    "Bank of America",
    "Bank of Baroda",
    "Bank of Bahrain & Kuwait Bsc",
    "Bank of India",
    "Bank of Maharashtra",
    "Barclays Bank",
    "Canara Bank",
    "Capital Small Finance Bank",
    "Central Bank of India",
    "Citi Bank",
    "City Union Bank",
    "Csb Bank",
    "Dbs Bank",
    "Dcb Bank",
    "Deutsche Bank",
    "Dhanalaxmi Bank",
    "Doha Bank Qpsc",
    "Equitas Small Finance Bank",
    "Esaf Small Finance Bank",
    "Federal Bank",
    "Fincare Small Finance Bank",
    "Fino Payments Bank",
    "Hdfc Bank",
    "Hsbc",
    "Icici Bank",
    "Idbi Bank",
    "Idfc First Bank",
    "India Post Payments Bank",
    "Indian Bank",
    "Indian Overseas Bank",
    "Indusind Bank",
    "Jammu & Kashmir Bank",
    "Jana Small Finance Bank",
    "Jio Payments Bank",
    "Karnataka Bank",
    "Karur Vysya Bank",
    "Keb Hana Bank",
    "Kookmin Bank",
    "Kotak Mahindra Bank",
    "Lakshmi Vilas Bank",
    "Nainital Bank",
    "North East Small Finance Bank",
    "Nsdl Payments Bank",
    "Paytm Payments Bank",
    "Punjab And Sind Bank",
    "Punjab National Bank",
    "Rbl Bank",
    "Sbm Bank India",
    "Shivalik Small Finance Bank",
    "South Indian Bank",
    "Standard Chartered Bank",
    "State Bank of India",
    "Suryoday Small Finance Bank",
    "Tamilnad Mercantile Bank",
    "Uco Bank",
    "Ujjivan Small Finance Bank",
    "Unity Small Finance Bank",
    "Utkarsh Small Finance Bank",
    "Woori Bank",
    "Yes Bank"
  ];


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://yieldlab.ai:9000/api/getBankWiseAverageTicketSize?cardType=${selectedCardType}&period=${selectedPeriod}&entity=${selectedEntity}&offset=${offset}`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCardType, selectedPeriod, selectedEntity, offset, selectedFirstCompare, selectedSecondCompare]);

  // Function to format numbers in the Indian numbering style
  function formatIndianNumbering(value) {
    if (value === undefined || value === null || isNaN(value)) {
      return ''; // Return an empty string or some default value if the input is invalid
    }

    const [integerPart, decimalPart] = value.toString().split('.');

    // Handle the integer part
    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);
    
    if (otherDigits !== '') {
      const formattedOtherDigits = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
      return formattedOtherDigits + ',' + lastThreeDigits + (decimalPart ? '.' + decimalPart : '');
    } else {
      return lastThreeDigits + (decimalPart ? '.' + decimalPart : '');
    }
  }

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
    if(period === 'Month'){
      setOffset(0);
    } else if (period === 'Quarter'){
      setOffset(0);
    } else {
      setOffset(0);
    }
  };

  const handlePrev = () => {
    setOffset(prevOffset => Math.max(prevOffset - 12, 0));
  };
  
  const handleNext = () => {
    setOffset(prevOffset => prevOffset + 12);
  };

  const handleCompareClick = () => {
    if(user){
      setShowModal(true);
    } else {
      navigate("/signin");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFirstCompareChange = (e) => {
    setSelectedFirstCompare(e.target.value);
  };

  const handleSecondCompareChange = (e) => {
    setSelectedSecondCompare(e.target.value);
  };

  const handleIndustyCompareChange = (e) => {
    setSelectedIndustyCompare(e.target.value);
  };

  const series = data.series || [];
  const xaxisCategories = data.xaxisCategories || [];

  const options = {
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      categories: xaxisCategories
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatIndianNumbering(value);
        }
      }
    },
    tooltip: {
      x: {
        format: 'MM/yy'
      }
    },
    legend: {
      show: true,
      showForSingleSeries: true,
    },
    responsive: [
      {
        breakpoint: 768, // Example breakpoint for small devices
        options: {
          xaxis: {
            labels: {
              rotate: -90 // Rotate labels 90 degrees for vertical display
            }
          }
        }
      }
    ]
  };

  return (
    <div className="container mt-7 chart-bg">
      <div className="row pt-4">
        <div className="col-12 col-lg-6 d-flex">
          <h4>Average Ticket size</h4>
        </div>
        <div className="col-12 col-lg-6 d-flex align-items-center justify-content-lg-end">
          <div className="d-flex flex-wrap gap-2">
            <Button
              className={`btn btn-outline-primary btn-light btn-sm rounded-pill ${selectedPeriod === 'Annual' ? 'active' : ''}`}
              onClick={() => handlePeriodChange('Annual')}
            >
              Yearly
            </Button>
            <Button
              className={`btn btn-outline-primary btn-light btn-sm rounded-pill ${selectedPeriod === 'Quarter' ? 'active' : ''}`}
              onClick={() => handlePeriodChange('Quarter')}
            >
              Quarterly
            </Button>
            <Button
              className={`btn btn-outline-primary btn-light btn-sm rounded-pill ${selectedPeriod === 'Month' ? 'active' : ''}`}
              onClick={() => handlePeriodChange('Month')}
            >
              Monthly
            </Button>
            {/* <Button
              className={`btn btn-outline-primary btn-light btn-sm rounded-pill ${selectedPeriod === 'Compare' ? 'active' : ''}`}
              onClick={handleCompareClick}
            >
              Compare
            </Button> */}

            <div className="d-flex align-items-center">
              <Button
                className="btn btn-outline-primary btn-light btn-sm"
                onClick={handleNext}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </Button>
              <Button className="btn btn-outline-primary btn-light rounded-0 btn-sm">
                <i className="fa-regular fa-calendar-days"></i>
              </Button>
              <Button
                className="btn btn-outline-primary btn-light btn-sm"
                onClick={handlePrev}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4 justify-content-between">
        <span>Value in Rs</span>
        {xaxisCategories?.length > 0 ? (
          <div className="mixed-chart"> 
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Chart options={options} series={series} type="area" />
            )}
          </div>
        ) : (
          <div className="mixed-chart text-center p-3">
            <img src="https://yieldlab.ai/asset/images/no-data.svg" alt="no-data" className="img-fluid" />
          </div>
        )}
      </div>
      {/* Modal for Compare */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Compare</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={4}>
                <Form.Group controlId="selectBank">
                  <Form.Control as="select" value={selectedFirstCompare} onChange={handleFirstCompareChange}>
                    <option value="">Select a Bank</option>
                    <optgroup label="Top Banks">
                      {topBanks.map((bank, index) => (
                        <option key={index} value={bank}>{bank}</option>
                      ))}
                    </optgroup>
                    <optgroup label="Other Banks">
                      {otherBanks.map((bank, index) => (
                        <option key={index} value={bank}>{bank}</option>
                      ))}
                    </optgroup>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="selectSecondCompare">
                  <Form.Control as="select" value={selectedSecondCompare} onChange={handleSecondCompareChange}>
                    <option value="">Select a Bank</option>
                    <optgroup label="Top Banks">
                      {topBanks.map((bank, index) => (
                        <option key={index} value={bank}>{bank}</option>
                      ))}
                    </optgroup>
                    <optgroup label="Other Banks">
                      {otherBanks.map((bank, index) => (
                        <option key={index} value={bank}>{bank}</option>
                      ))}
                    </optgroup>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="selectIndustyCompare">
                  <Form.Control as="select" value={selectedIndustyCompare} onChange={handleIndustyCompareChange}>
                    <option value="Industry">Industry</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Compare
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AverageTicketSize;
