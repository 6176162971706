import { Container, Row, Col } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import Home from "./components/home/Home";
import Login from "./components/login/Login";
import Signup from "./components/signup/Signup";

import ProtectedRoute from "./routes/ProtectedRoute";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import "./index.css";
import PaymentTrends from "./components/payment-trends/PaymentTrends";
import BankPaymentTrends from "./components/bank-payment-trends/BankPaymentTrends";
import AboutUs from "./components/about-us/AboutUs";
import ReactGA from 'react-ga4';
import PrivacyPolicy from "./components/privacy-policy/PrivacyPolicy";
function App() {
  ReactGA.initialize('G-TPFFNXCJN3');
  return (
    <UserAuthContextProvider>
    <Routes>
      <Route 
        path="/home"
        element={
          // <ProtectedRoute>
            <Home />
          // </ProtectedRoute>
        }
      />
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/payment-trends" element={<PaymentTrends />} />
      <Route path="/payment-trends/:cardName" element={<PaymentTrends />} />
      <Route path="/payment-trends/:cardName/:bankName" element={
                                                              // <ProtectedRoute>
                                                                <BankPaymentTrends />
                                                                // </ProtectedRoute>
                                                              } />
      <Route path="/signup" element={<Signup />} />
    </Routes>
  </UserAuthContextProvider>
  );
}

export default App;
