import React, { useState, useEffect } from 'react';
import  Footer  from "../layouts/footer/Footer";
import Header from '../layouts/header/Header';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
const AboutUs = () => {
  
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "About_Us" });
  return (
    <div className="app">
      <Header />
      <Helmet>
        <meta name="description" content="Gain insights into India's credit card market with YieldLab.ai. Track market share, spending trends, and industry developments for a competitive edge" />
      </Helmet>
        <div className="container mt-5 m-container about-bg">
          <div className="row justify-content-between">
            {/* <h1 className="page-title">YieldLab.ai | Credit & Debit Card Analytics</h1> */}
            <div className="row">
              <div className="col-lg-8 d-flex p-4">
                <h1 className="info-text-3">Driving Innovation in the Credit Card Market with AI-Powered Insights</h1>
              </div>
              <div className="col-lg-4 d-flex align-items-center justify-content-center text-center">
                <img src="asset/images/Brain_Signals.png" alt="Driving Innovation in the Credit Card Market with AI-Powered Insights" className="img-fluid" />
              </div>
              <div className="col-lg-12 d-block p-4">
                <p>YieldLab.ai is a platform for tracking and analysing the credit card market in India. We provide valuable insights into market share, trends, user spending patterns, and industry developments, helping businesses stay informed in the fast-evolving credit card landscape.</p>
                <p>In addition to market intelligence, we offer expert consulting services to help banks optimise their credit card portfolios.</p>
                <p>Whether you’re exploring credit card penetration or looking to drive higher engagement, YieldLab.ai combines data and expert analysis to empower your growth in the credit card market.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-7 m-container justify-content-between">
          <div className="row justify-content-between">
            <div className="col-lg-6 d-flex p-4">
              <div className="about-bg-2 p-5">
                  <img src="asset/images/Vision.png" alt="Our Vision" className="img-fluid" />
                  <h1 className="info-text-3">Our Vision</h1>
                  <p>To be the leading AI-powered platform driving data-driven innovation in the financial services industry, empowering businesses with advanced insights and solutions that shape the future of credit markets and optimise performance.</p>
              </div>
            </div>
            <div className="col-lg-6 d-flex p-4">
                <div className="about-bg-3 p-5">
                    <img src="asset/images/Human_Science.png" alt="Our Mission" className="img-fluid" />
                    <h1 className="info-text-3">Our Mission</h1>
                    <p>Our mission is to leverage AI-driven technology to deliver actionable insights, helping financial institutions optimize credit card portfolios, enhance customer experiences, and stay ahead of trends, empowering data-driven decisions for sustainable growth through advanced analytics and partnerships.</p>
                </div>
              </div>
          </div>
        </div>
      <Footer />
    </div>
  );
};

export default AboutUs;