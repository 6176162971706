import React, { useState, useEffect } from "react";
import Header from '../layouts/header/Header';
import Footer from '../layouts/footer/Footer';
import { Helmet } from 'react-helmet';
import "./style.css";
import ReactGA from 'react-ga4';

const PrivacyPolicy = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Privacy_Policy" });
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  return (
    <div className="app">
      <Header />
      <Helmet>
        <meta name="description" content="Privacy Policy" />
      </Helmet>
        <div className="container mt-5 m-container credit-debit-bg">
          <div className="row justify-content-between">
            <h1 className="page-title">Privacy Policy</h1>
            <h2>1. Introduction</h2>
            <div className="col-lg-12 d-block order-lg-1">
            <p>
              YieldLab Technologies Pvt. Ltd. ("we," "us," or "our") is committed to protecting the privacy of our platform’s visitors and users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://yieldlab.ai" target="_blank" rel="noopener noreferrer">https://yieldlab.ai</a> (the "Site") or our reports, social channels (the ‘properties’).
            </p>

            <h2>2. Information We Collect</h2>
            <h3>a. Personal Data</h3>
            <p>
              We may collect personal information that you voluntarily provide to us when you register on the Site or on the properties, subscribe to our newsletter, request information, or engage with our services. This may include:
            </p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Company information</li>
              <li>Other information you choose to provide</li>
            </ul>

            <h3>b. Usage Data</h3>
            <p>
              We automatically collect certain information when you visit, use, or navigate the Site. This may include:
            </p>
            <ul>
              <li>IP address</li>
              <li>Browser type</li>
              <li>Operating system</li>
              <li>Access times and dates</li>
              <li>Pages viewed</li>
            </ul>

            <h3>c. Cookies and Tracking Technologies</h3>
            <p>
              We may use cookies and similar tracking technologies to collect and store information about your interactions with the Site or our properties.
            </p>

            <h2>3. How We Use Your Information</h2>
            <p>We use the information we collect in the following ways:</p>
            <ul>
              <li>To operate and maintain the Site</li>
              <li>To improve user experience and the content of our Site</li>
              <li>To send newsletters, updates, and other communications</li>
              <li>To respond to inquiries and provide customer support</li>
              <li>To analyze usage patterns and trends</li>
              <li>To comply with legal obligations</li>
            </ul>

            <h2>4. Sharing Your Information</h2>
            <p>
              We do not sell, trade, or rent your personal information to third parties. However, we may share information with:
            </p>
            <ul>
              <li>Service providers who assist us in operating our Site and providing services</li>
              <li>Compliance with legal processes, such as court orders or law enforcement requests</li>
              <li>Protecting our rights, privacy, safety, or property, and that of our users</li>
            </ul>

            <h2>5. Data Security</h2>
            <p>
              We implement appropriate technical and organizational security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no security measures are entirely foolproof.
            </p>

            <h2>6. Third-Party Links</h2>
            <p>
              Our Site may contain links to third-party websites. We are not responsible for the privacy practices or the content of these external sites. We encourage you to read the privacy policies of any third-party websites you visit.
            </p>

            <h2>7. Your Rights</h2>
            <p>
              Depending on your location, you may have the following rights regarding your personal information:
            </p>
            <ul>
              <li>The right to access, update, or delete the personal information we have about you</li>
              <li>The right to object to or restrict certain processing of your data</li>
              <li>The right to withdraw consent where we have relied on it for processing your data</li>
              <li>The right to lodge a complaint with a data protection authority</li>
            </ul>

            <h2>8. Data Retention</h2>
            <p>
              We will retain your personal information only for as long as necessary for the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted by law.
            </p>

            <h2>9. Changes to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically for any updates.
            </p>

            <h2>10. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy or our data practices, please contact us at:
            </p>
            <p>Email: <a href="mailto:privacy@yieldlab.ai">privacy@yieldlab.ai</a></p>
            </div>
          </div>
        </div>
      <Footer />
    </div>

  );
};

export default PrivacyPolicy;
